<template>
  <div class="mt-3">
    <div class="border-2 rounded p-3">
      <div class="flex items-center w-auto cursor-pointer" @click="toggle">
        <fa
          icon="angle-right"
          size="md"
          class="text-primary"
          :class="{
            'transform rotate-90': isOpen,
            'transform rotate-0': !isOpen,
          }"
        />
        <div class="font-semibold ml-3">{{ title }}</div>
      </div>
      <div class="accordion__content" v-show="isOpen">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Accordion",
  props: {
    title: {
      required: true,
    },
    expand: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
  watch: {
    expand: function (val) {
     this.isOpen = val;
    },
  },
});
</script>

<style lang="scss" scoped></style>
